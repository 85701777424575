<template>
  <validation-observer ref="formLevel">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[2].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="level"
                  >Level</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama level"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.name"

                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="level"
                  >Type</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama level"
                    rules="required"
                  >
                    <b-form-select
                      id="name"
                      v-model="models.type"
                      :options="masters.types"

                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="index"
                  >Index</label>
                </b-col>
                <b-col sm="9">
                  <validation-provider
                    #default="{ errors }"
                    name="Index"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="index"
                      v-model="models.index"

                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="2">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="9">
                  <div class="col-sm-12">
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="badge badge-danger">Tidak Aktif</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BButton, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { required, numeric } from '@validations'

export default {
  name: 'FormLevelKFS',
  metaInfo: {
    title: 'Form Level KFS',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormSelect,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        name: '',
        index: '',
        status: true,
        type: 1,
      },
      masters: {
        types: [
          { value: 1, text: 'Distributor' },
          { value: 2, text: 'Reseller' },
        ],
      },
      required,
      numeric,
    }
  },
  created() {
    this.getById()
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.level}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.name = data.name
            this.models.index = data.index
            this.models.status = data.status
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    save() {
      this.$refs.formLevel.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (!this.$route.params.id) {
            request = this.$axios.post(api.level, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.level}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} level`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
